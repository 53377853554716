import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
//import gsap from 'gsap';
//import ScrollTrigger from 'gsap/ScrollTrigger';
import 'slick-carousel';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    //this.initGreenSock();
    this.initSliders();
    this.initHeadroom();
    this.initTabs();
    this.initNavDropdowns();
    this.initMobileDropdowns();
    this.openBar(true);
    this.handlePopup(true);
    this.setCookie();
    this.getCookie();
    this.formConfirmations();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.announcement-bar__close', event => this.closeBar(event));
    this.$doc.on('click', '.announcement-popup__close', event => this.closePopup(event));
  },
  initGreenSock() {
    function animateSimple(elem) {
      gsap.to(elem, {
        duration: 0.5,
        y: 0,
        autoAlpha: 1,
        ease: "power1.out",
        stagger: 0.2,
        delay: 0.3,
        overwrite: 'auto'
      });
    }
    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0, y: 50});
    }
    document.addEventListener("DOMContentLoaded", function() {
      gsap.registerPlugin(ScrollTrigger);
      gsap.utils.toArray(".js-fadein").forEach(function(elem) {
        hide(elem); // assure that the element is hidden when scrolled into view
        ScrollTrigger.create({
          trigger: elem,
          onEnter: function() { animateSimple(elem) },
        });
      });
    });
  },
  initSliders() {
    $('.js-mobile-icon-cards').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      infinite: true
    });
    $('.js-logos').slick({
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }]
    });
  },
  initNavDropdowns() {
    let parentMenuItem;
    let activeMenuItem;
    let activeSubmenu;
    const submenus = $('.nav--main .nav__list ul');
    let submenuItems = submenus.children('li');

    submenuItems.mouseenter(
      function() {
        activeMenuItem = $(this);
        activeSubmenu = $(this).children('.sub-menu');
        parentMenuItem = activeMenuItem.parent('.sub-menu');
        if(activeMenuItem.hasClass('menu-item-has-children')) {
          parentMenuItem.animate({
            'width': '420px'}, 200
          );
        }
        else {
          if(!parentMenuItem) { return };
          parentMenuItem.animate({
            'width': '200px'}, 200
          );
        }
      }
    ).mouseleave(
      function() {
        if(activeMenuItem) { return }
      }
    );

  },
  initMobileDropdowns() {
    $('.js-mobile-nav .menu-item-has-children').attr('aria-expanded', 'false');
    $('.js-mobile-nav .menu-item-has-children').append( '<span><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.9515 9L5.58203 10.276L12.7911 17L20.0001 10.276L18.6306 9L12.7911 14.4467L6.9515 9Z" fill="white"/><circle cx="12.5" cy="12.5" r="11.5" transform="rotate(90 12.5 12.5)" stroke="white" stroke-width="2"/></svg></span>');
    $('.js-mobile-nav .menu-item-has-children > span').click(function() {
      $(this).siblings('.sub-menu').slideToggle(500);
      $(this).parent().toggleClass('submenu-active');
      $(this).parent().attr('aria-expanded',
        $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
      );
    });
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  formConfirmations() {
    jQuery(document).on('gform_confirmation_loaded', function(event, formId){
      $('.gform_wrapper').parent().addClass('confirmation-loaded');
      $('.gform_confirmation_wrapper').parent().addClass('confirmation-loaded');
      setTimeout(function() {
        $('html').animate( {
            scrollTop: $('.wysiwyg-editor.confirmation-loaded').offset().top - 200
          }, 400
        );
      }, 200);
    });
  },
  initTabs() {
    const tabs = $('.tabs').find('.tabs__nav');
    tabs.find('li:first a').addClass("active").attr('aria-selected', 'true');
    $('#tab1').addClass("active").attr('aria-expanded', 'true');

    displayTabs();

    let resizeTimer = null;
    $(window).on('resize', function(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        displayTabs();
    }, 250);
    });

    function displayTabs() {
      if(tabs.is(':visible')) {
        $('.item').attr('aria-expanded', 'false');
        tabs.find('a').each(function() {
          if($(this).hasClass('active')) {
            $(this).attr('aria-selected', 'true');
            const activeItem = $(this).attr('aria-controls');
            $(`#${activeItem}`).addClass('active');
          }
        });
        tabs.find('a').on('click', function(event) {
          event.preventDefault();
          const target = $(this).attr('href'),
              tabs = $(this).parents('.tabs__nav'),
              buttons = tabs.find('a'),
              item = tabs.parents('.tabs').find('.item');
          $(buttons).not($(this)).attr('aria-selected', 'false');
          $(this).attr('aria-selected', 'true');
          buttons.removeClass('active');
          item.removeClass('active');
          $(this).addClass('active');
          $(target).addClass('active');
        });
      } else {
        tabs.find('a').attr('aria-selected', 'false');
        $('.item').each(function() {
          if($(this).hasClass('active')) {
            $(this).attr('aria-expanded', 'true');
          }
        });
        $('.item').on('click', function() {
          const container = $(this).parents('.tabs'),
                currId = $(this).attr('id');
          container.find('.tabs__nav a').removeClass('active');
          $(this).hasClass('active') ? $(this).removeClass('active') : $(this).addClass('active');
          $('.item').not($(this)).removeClass('active');
          $('.item').not($(this)).attr('aria-expanded', 'false');
          $(this).attr('aria-expanded',
            $(this).attr('aria-expanded')=='false' ? 'true' : 'false');
          container.find('.tabs__nav a[href$="#'+ currId +'"]').addClass('active');
        });
      }
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  getCookie(c_name) {
    var i, x, y, cookies = document.cookie.split( ';' );

    for ( i = 0; i < cookies.length; i++ ) {
      x = cookies[i].substr( 0, cookies[i].indexOf( '=') );
      y = cookies[i].substr( cookies[i].indexOf( '=') + 1 );
      x = x.replace( /^\s+|\s+$/g, '' );

      if ( x === c_name ) {
        return unescape( y );
      }
    }
  },
  setCookie(c_name, value, exdays) {
    var c_value = escape(value);
    if (exdays) {
      var exdate = new Date();
      exdate.setDate( exdate.getDate() + exdays );
      c_value += '; expires=' + exdate.toUTCString();
    }
    document.cookie = c_name + '=' + c_value;
  },
  closeBar(event) {
    event.preventDefault()
    const bar = $('.js-bar');
    this.$header.css('paddingTop', 0);
    this.$mainWrap.css('paddingTop', `${this.$mainWrap.attr('paddingtop')}px`);
    bar.css('top', `-${bar.outerHeight()}px`);
  },
  openBar(init) {
    const bar = $('.js-bar');
    if (init && bar.length > 0) {
      if (this.getCookie('bar')) {
        return;
      }
      bar.addClass('is-loaded');
      const mainPadding = parseInt(this.$mainWrap.attr('paddingtop')) + bar.outerHeight();
      this.$header.css('paddingTop', `${bar.outerHeight()}px`);
      this.$mainWrap.css('paddingTop', `${mainPadding}px`);
      bar.css('top', 0);
      this.setCookie('bar', 'yes', 7);
    }
  },
  closePopup(event) {
    event.preventDefault();
    const popup = $('.js-popup');
    popup.fadeOut('slow');
  },
  handlePopup(init) {
    const popup = $('.js-popup');
    if (init && popup.length > 0) {
      if (this.getCookie('popup')) {
        return;
      }
      setTimeout(function() {
        popup.fadeIn('slow');
      }, 1000);
      this.setCookie('popup', 'yes', 7);
    }
  }
};

Mangrove.init();
